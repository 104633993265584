import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import HeroCard from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
import GetStarted from "components/cta/GetStarted";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"
import tw from "twin.macro";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero />
      <HeroCard
        heading={<>SwasthSeva makes <HighlightedText>Telemedicine</HighlightedText> Accessible</>}
        description="Run as a social business, SwasthSeva provides healthcare institutions with the infrastructure and skills to remotely treat patients."
        imageSrc="https://source.unsplash.com/hIgeoQjS_iE/600x400"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Explore SwasthSeva"
        watchVideoButtonText="Video Coming Soon"
      />
      <MainFeature
        subheading={<Subheading>Established Since 2014</Subheading>}
        heading={
          <>
            Trst is the Made in India
            <wbr /> <HighlightedText>Blockchain</HighlightedText>
            Ecosystem
          </>
        }
        description={
          <Description>
            We have pursued Proof of Concepts with top Indian Enterprises, and are currently looking to build partners for production deployment.
            <br />
            <br />
            We also have an experimental in-house experimental TrstWeb Ecosystem, that has been re-engineered ground up, for specialised and niche use cases.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Make Your Department/Company an Early Adopter!"
        imageSrc={
          "https://source.unsplash.com/4kIM7ED8F1A/600x400"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <FAQ />
        <Testimonial />
      <Features
        heading={
          <>
            We help you <HighlightedText>Build and Grow.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "Product Design",
            description: "Design Sprints, Research, User Interface Design, and Mobile Design.",
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Product Engineering",
            description: "Data Science, Distributed Computing, API & Mobile App Development, Web Development.",
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Product Management",
            description: "Roadmap Development, Backlog Prioritization, Agile Sprint based Deliveries, and Product Strategy.",
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
